@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/Questrial-Regular.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/KronaOne-Regular.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", serif;
$font-3: 'Alegreya Sans', sans-serif;
$font-4: 'Josefin Sans', sans-serif;

$color-1: #fafeff;
$color-2: #00ADF4;
$color-3: #e7f7fe;
$color-4: rgb(141, 141, 141);
$black: rgb(40, 40, 40);
$red: rgb(253, 87, 87);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  &::selection {
    color: $color-1;
    background: $color-2;
  }
}
html {
  /*scroll-behavior: unset;
  min-height: 100% !important;
  height: 100%;*/
}
a, a:visited {
  color: $color-2;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $color-2;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
    &:not(.right, left) {
      visibility: hidden;
    }
  }
}


body {
  font-family: $font-4;
  height: 100vh;
  background-color: $color-1;
  color: $black;
  overflow: hidden;

  @media screen and (max-width: 650px){
    position: relative;
    max-width: 100vw;
  }
}
main {
  position: relative;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
