.navigation {
  position: absolute;
  top: 15px;
  left: 8%;
  font-size: 1.4rem;
  line-height: 28px;
  color: $color-4;
  z-index: 2;


  @media screen and (max-width: 650px) {
    display:none;

  }

  &:before {
    content: '';
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background: $color-3;
    top: -150px;
    left: -160px;
    z-index: -10000;
    animation: navAnim 20s infinite alternate;

    @keyframes navAnim {
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
    @media screen and (max-width: 430px){
      height: 50px;
      width: 50px;
    }
  }
  .nav-active {
    color: $black;
  }

  ul {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;

    &:hover li:not(:hover) {
      opacity: .5;
    }

    &:hover li:hover {
      color: $black;
    }
  }

  /*
  //Sous menu
  .nav-portfolio {
    cursor: pointer;
    display: flex;
    // padding-right: 6rem;
    transition: .5s;

    &:hover .nav-projects,
    .nav-projects:hover {

      visibility: visible;
      opacity: 1;

      li {
        transform: rotatey(0deg)
      }
    }

    .nav-projects {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      padding: 0 40px;
      width: 300px;
      transform: translateX(70px);
      transition: .3s ;
      perspective: 500px;

      li {
        transform: rotatey(30deg) scaleX(1.2);
        transform-origin: center;
        transition: .3s;
      }
    }
  }
  */

}


/* Position and sizing of burger button */
.bm-burger-button {
  display:none;

  @media screen and (max-width: 650px) {
    display:block;
    position: fixed;
    width: 36px;
    height: 25px;
    left: 86px;
    top: 19px;


    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: $color-2;
    }
  }
}



/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $color-1;
  }
  /* General sidebar styles */
  .bm-menu {
    background: $color-2;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: $color-2;
  }

  /* Wrapper for item list */
  .bm-item-list {
    padding: 0.8em;
    a{
      padding: 0.5em;
      font-size: x-large;
      color: $color-1;
    }
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
