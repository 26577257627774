.home {
  height: 100vh;
  width: 100vw;
  position: absolute;

  &:after {
    content: '';
    height: 900px;
    width: 900px;
    background: $color-3;
    border-radius: 50%;
    position: absolute;
    bottom: -480px;
    right: 0px;
    z-index: -2;
    animation: homeAnim 15s infinite alternate;

    @media screen and (max-width: 650px){
      height: 400px;
      width: 400px;
      bottom: -270px;
    }

    @keyframes homeAnim {
      50% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1.1);
      }
    }
    @media screen and (max-width: 430px){
      display: none;
    }
  }

  .bm-burger-button {
    @media screen and (max-width: 1024px) {
      left: 16px;
    }
  }
  .home-main {
    display: grid;
    align-items: center;
    justify-items: center;
    padding-left: 1rem;
    width: auto;
    height: 95%;
    overflow: hidden;


    .main-content {
      user-select: none;

      @media screen and (max-width: 650px){
        padding-left: 2rem;
      }





      h1 {
        font-size: 3rem;
        position: relative;
        font-family: $font-2;
        color: $black;

        @media screen and (max-width: 1024px){
          font-size: 3rem;
        }
        @media screen and (max-width: 812px){
        font-size: 2rem;
        }
        @media screen and (max-width: 650px){
          font-size: 1.2rem;
        }



        &:before {
          content: '';
          position: absolute;
          height: 200px;
          width: 200px;
          border-radius: 50%;
          border: 1px $color-2 solid;
          top: -40px;
          left: -120px;
          z-index: -1;
          animation: h1Anim 1.5s forwards ease;
          transform: scale(0);

          @media screen and (max-width: 1024px){
            height: 125px;
            width: 125px;
            left: -45px;
            top: -20px;
          }


          @media screen and (max-width: 812px){
            height: 90px;
            width: 90px;
            left: -40px;
            top: -15px;
          }

          @media screen and (max-width: 650px){
            height: 45px;
            width: 50px;
            left: -20px;
            top: -5px;
          }

          @keyframes h1Anim {
            to {
              transform: scale(1);
            }
          }
        }
      }
      h2, h3 {
        font-size: 3.2rem;
        font-weight: 100;

        @media screen and (max-width: 1200px){
          font-size: 2.5rem;
        }
        @media screen and (max-width: 812px){
        font-size: 2rem;
        }
        @media screen and (max-width: 650px){
          font-size: 1rem;
        }


      }

    }
  }
}
