
.bottom {
  position: absolute;
  height:35px;
  width:100%;
  background-color: $color-1;
  bottom: 0;

  .scroll-bottom {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    color: $color-4;
    font-size: 1.7rem;
    perspective: 40px;


    &:hover .sb-main {
      pointer-events: auto;
    }

    .sb-main {
      display: grid;
      grid-template-columns: 20px 100px 20px;
      grid-template-areas: 'a b c';
      align-items: center;
      justify-items: center;
      width: 150px;
      transition: 0.4s;
      transform: rotateX(25deg) scaleY(0.8);
      transform-origin: bottom;
      pointer-events: none;
      /*
          @media screen and (max-width: 650px){
            transform: rotateX(0deg) scaleY(1);
            width: 145px;
          }
          .left, .right {
            @media screen and (max-width: 650px){
              transform: scale(2);
            }
          }*/
      .left {
        grid-area: a;
      }

      p {
        grid-area: b;

        /*@media screen and (max-width: 650px){
          display: none;
        }*/
      }

      .right {
        grid-area: c;
      }

      &:hover {
        transform: rotateX(0deg) scaleY(1);
      }

      span {
        font-size: 1.5rem;
        padding: 0 1rem;
        transition: 0.3s;
        display: inline-block;
        transform: translateY(-1px);

        &:hover {
          color: $black;
          font-size: 1.8rem;
        }
      }
    }
  }
}
