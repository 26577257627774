.page {
  position: absolute;
  height: 100vh;
  width: 100vw;

  &:after {
    content: "";
    height: 300px;
    width: 300px;
    border: 1px solid $color-2;
    border-radius: 50%;
    position: absolute;
    bottom: 40px;
    left: -220px;
    z-index: -1;

    @media screen and (max-width: 810px){
      height: 200px;
      width: 200px;
      bottom: -110px;
    }
    @media screen and (max-width: 430px){
      // display: none;
      left : -500px;
    }
  }


  .navigation {
    top: 15px;
    left: 12%;
    font-size: 1.1rem;
    line-height: 25px;
    transform: translateY(-100%);
    animation: navProject 1s ease forwards;

    @media screen and (max-width: 650px){
      left: 70%;
    }

    @keyframes navProject {
      to {
        transform: translateY(0);
      }
    }

    &:before {
      left: -120px;
      top: -250px;
    }

    .nav-projects {
      transform: translateX(67px);
      padding: 0 20px;
    }
  }


  .logo {
    top: 15px;
    left: 1%;
    z-index:100;
    transform: translateX(-50%);
    transform: scale(0);
    animation: logoProject 2s ease forwards;
    @keyframes logoProject {
      to {
        transform: scale(1);
      }
    }
  }
  .spacer{margin-top:40px;}
  .point:hover{
    cursor: pointer;
    pointer-events: bounding-box;
  }


  .main {
    margin: 5.5rem 0;
    padding:0rem 3rem;
    display: grid;
    grid-template-columns: 35% 65%;
    height:calc(100% - 150px);
    grid-template-rows: 100% .5fr;
    position: relative;
    text-align: right;
    overflow-y: scroll;
    overflow-x: hidden;


    &.decal{
      grid-template-columns: 45% 55%;
      .content{
        p{margin-left:15%;}
      }

      @media screen and (max-width: 1024px){
        grid-template-columns: 35% 65%;
      }
      @media screen and (max-width: 850px){
        grid-template-columns: 45% 55%;
      }

    }



    @media screen and (max-width: 1024px){
      grid-template-columns: 35% 65%;
      height:calc(100% - 120px);

    }

    @media screen and (max-width: 812px){
      grid-template-columns: 35% 65%;
      padding:0;
    }

    @media screen and (max-width: 650px){
      display:block;
      margin:3rem 10px;
      padding:0;
      text-align: left;
    }


    .left {
      margin: 0px 10px;
      margin-top:20px;
      font-size:1.2rem;

      @media screen and (max-width: 650px){
        margin:20px 0px;
      }

      .vignette {
        position:absolute;
        top:90px;
        left:8%;
        width: 170px;
        height: 170px;
        border-radius: 50%;
        background-size: contain;
        box-shadow: inset 0 0 40px rgba(0, 0, 0, 1);
        overflow: hidden;

        @media screen and (max-width: 1024px){
          left:5%;
          width: 120px;
          height: 120px;
        }
        @media screen and (max-width: 820px){
          top:65px;
          left:40px;
          width: 90px;
          height: 90px;
        }
        @media screen and (max-width: 450px){
          position:absolute;
          top:0px;
          left:calc(100% - 100px);
          width: 90px;
          height: 90px;
        }
      }


      h1, h2 {
        font-size: 3rem;
        font-family: $font-2;
        color: $color-2;
        transform: scaleX(0);
        animation: transformTitle 1.5s ease forwards;
        transform-origin: 150%;

        @media screen and (max-width: 1024px){
          font-size: 1.8rem;
        }
        @media screen and (max-width: 812px){
          font-size: 1.5rem;
        }
        @media screen and (max-width: 650px) {
          font-size: 1.2rem;
        }

        @keyframes transformTitle {
          to {
            transform: scaleX(1);
          }
        }

      }
      h2 {
        font-size: 2rem;
        @media screen and (max-width: 1024px){
          font-size: 1.5rem;
        }
        @media screen and (max-width: 812px){
          font-size: 1.2rem;
        }
        @media screen and (max-width: 650px) {
          font-size: 0.9rem;
        }
      }

      p{ margin-bottom: 20px; }
      .type {
        margin: 10px 0 30px 0;
        color: $color-4;
        font-size: 1.2rem;
        @media screen and (max-width: 812px){
          margin: 0;
        }
        @media screen and (max-width: 450px){
          font-size: .9rem;
        }

        &:after {
          content: "";
          height: 340px;
          width: 340px;
          background: $color-3;
          border-radius: 50%;
          position: absolute;
          top: -28px;
          left: 70px;
          z-index: -10;

          @media screen and (max-width: 810px){
            height: 220px;
            width: 220px;
            left: -30px;
          }
        }
      }
      li {
        margin-top: 25px;
        font-size: 1.5rem;

        @media screen and (max-width: 1024px){
          font-size: 1.2rem;
        }
        @media screen and (max-width: 812px) and (orientation: landscape) {
          font-size: 1rem;
          margin-top: 10px;
        }
        @media screen and (max-width: 650px) {
          display: inline-block;
          margin: 5px 5px;
          font-size: .8rem;
        }
      }
    }


    .right {
      text-align: left;
      margin:39px 20px 0px 20px;
      font-size: 1.2rem;

      #methoGraph{ width:100%;height:auto; margin:0; padding:0}


      @media screen and (max-width: 1024px){
        margin-top:29px;
      }
      @media screen and (max-width: 650px){
        display:initial;
        /*svg{ width:100%;height:320px; margin:0; padding:0}*/
      }

      p{ padding-bottom: 20px; }

      .language{
        @media screen and (max-width: 850px){
          margin-left:2.5rem;
        }

        li {
          border-radius: 10px 10px 10px 10px;
          -moz-border-radius: 10px 10px 10px 10px;
          -webkit-border-radius: 10px 10px 10px 10px;
          background-color: #e7f7fe;
          display: inline-block;
          list-style: none; /* pour enlever les puces sur IE7 */

          -webkit-box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);
          -moz-box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);
          box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);

          margin: 5px 20px 10px 0px;
          padding-bottom: 5px;
          text-align: center;

          font-size: .7em;
          color: $font-2;
          font-weight: 100;

          /*@media screen and (max-width: 650px){
            display:initial;
          }*/


          img{
            padding:5px;
            width:80px!important;
            height:80px!important;

            @media screen and (max-width: 850px){
              width:50px!important;
              height:50px!important;
            }

          }

        }
      }
      .portfolio{

        li {
          border-radius: 10px 10px 10px 10px;
          -moz-border-radius: 10px 10px 10px 10px;
          -webkit-border-radius: 10px 10px 10px 10px;
          background-color: #e7f7fe;
          width: 120px;
          height:120px;
          overflow: hidden;
          list-style: none; /* pour enlever les puces sur IE7 */

          -webkit-box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);
          -moz-box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);
          box-shadow: 10px 10px 11px 0px rgba(237,240,239,1);

          margin: 5px 20px 10px 0px;
          padding-bottom: 5px;
          color: $font-2;
          cursor:pointer;

          display: grid;
          grid-template-columns: 140px auto;

          @media screen and (max-width: 1024px){
            width:auto;
          }

          img{
            filter: grayscale(100%);
            padding:10px;
            width:110px!important;
            height:110px!important;

            @media screen and (max-width: 1024px){
              filter:none;
            }

          }


          .other{
            font-size: larger;
            opacity: 0;
            padding: 20px;
            transition-timing-function: ease-in;
            transition: 0s;
            transform: translateX(-10%);

            @media screen and (max-width: 1024px){
              opacity:1;
              .type{
                font-size: 1rem;
                color:gray;

              }
              .title{
                margin-top:15px;
                color:$color-2;

              }
              .title2{
                font-size: 1.3rem;
                color:$color-4;

              }
            }
          }

          &:hover{
            width:100%;

            img{
              filter: grayscale(0%);
              padding:5px;
              width:120px!important;
              height:120px!important;
            }


            .other{
              opacity: 1;
              transition-timing-function: ease-out;
              transition: 0.25s;
              transform: translateX(0%);


              .type{
                font-size: 1rem;
                color:gray;

              }
              .title{
                margin-top:15px;
                color:$color-2;

              }
              .title2{
                font-size: 1.3rem;
                color:$color-4;

              }
            }
          }
        }
      }
    }
  }



  .vertical-timeline {
    /*height:97%!important;*/

    &:before {
      background: #00ADF4;
    }

    .vertical-timeline-element-title{
      font-size:1em;
    }
    .vertical-timeline-element-subtitle{
      font-size:0.8em;
    }
    .vertical-timeline-element-content {
      background: #e0eeff;
    }

    .vertical-timeline-element-date {
      left: 132%;
      right: 120%;
    }

    .listLogo{
      margin-top:-10px;
      float:right;
      /*display: flex;*/

      img{
        padding:0 5px;
        @media screen and (max-width: 850px){
          width:50px!important;
        }
      }
    }


  }
  .random-circle {
    position: absolute;
    height: 400px;
    width: 400px;
    background: $color-3;
    border-radius: 50%;
    z-index: -1;
    animation: circleAnim 16s infinite alternate;

    @keyframes circleAnim {
      50% {
        transform: scale(0.7);
      }
      100% {
        transform: scale(1);
      }
    }

    @media screen and (max-width: 630px){
      display: none;
    }
  }
}

.contact-form {
  max-width: 70%;

  @media screen and (max-width: 850px){
    max-width: 90%;
    margin:0 5%;
  }

  &.cv{
    max-width: 100%;
  }

  h2 {
    margin-bottom: 10px;

    &:after {
      content: "";
      height: 70px;
      width: 70px;
      border: 1px $color-2 solid;
      border-radius: 50%;
      position: absolute;
      top: -22px;
      left: 133px;
      z-index: -1;
    }
  }


  button{
    padding:0.7em 1.4em;
    //margin:0 0.3em 0.3em 0;
    border-radius:1em;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-weight:400;
    color:#FFFFFF;
    background-color:$color-2;
    box-shadow:inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
    text-align:center;
    float : right;

    &:hover {
      box-shadow:inset 0 -0.6em 0 -0.35em $color-3;
    }

    &:active{
       top:0.1em;
    }
  }


  .form-content {
    display: grid;
    grid-template-rows: repeat(4, 1fr) 200px;

    &.cv{
      grid-template-rows: auto;
      padding: 20px;
    }

  }
  .form-message, .form-messageCV {
    margin: 0 0 15px 0;
    padding: 12px;
    display: none;
    background: $color-2;
    transition: 0.3s ease;
    color: $color-1;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
  }
}



.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 65%;

  @media screen and (max-width: 850px){
    /*top: 70px;*/
    width: 90%;
    height: 90%;
    /*transform: translate(-50%, 0%);*/
  }


  &.cvModal{
    width: 35%;
    @media screen and (max-width: 850px){
      width: 90%;
      height: auto;
    }

  }

  .btnClose {
    float: right;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: -10px;
  }

  h1 {
    color: $color-2;
    margin-bottom: 5px;
  }
  h2 {

    margin-bottom: 15px;
  }

  .content {
    display: grid;
    grid-template-columns: 20% auto;

    @media screen and (max-width: 850px){
      grid-template-columns: 100%;
    }


    .left {
      @media screen and (max-width: 850px){
        display:none;
      }
      ul {
        margin: 3px 20px 0 0px;
        text-align: right;
        font-size: 1.1rem;
        color: $color-4;
        li {
          padding-bottom: 8px;
        }
      }
      .logos{
        position:absolute;
        bottom:20px;

        img{
          display: block;
          width: 150px;
          padding:20px 0;

          @media screen and (max-width: 1024px){
            width: 90px;
          }

          @media screen and (max-width: 850px){
            width: 70px;
          }

        }
      }
    }

    .right {
      .desc {
        font-size: 1.2rem;

        p {
          margin-bottom: 20px
        }
      }

      .mansory{
        background-color: #00adf4;
        margin-top:15px;
        padding:10px;
      }

    }
  }
  .liste {
    display: grid;
    grid-template-columns: 140px auto;
    margin:20px 20px;

    @media screen and (max-width: 850px){
      grid-template-columns: 50px auto;
      margin:20px 10px;

    }

    .left {
      margin-left:20px;
      top:0;

      @media screen and (max-width: 850px){
        margin-left: -15px;
      }

      img{
        width: 90px;
        @media screen and (max-width: 850px){
          width: 50px;
        }
      }
    }

    .right {
      h4{
        font-size: 1.2rem;
        span{
          font-size: 1rem;
          font-weight: lighter;
          color:$color-4;
        }
      }
      .desc {
        font-size: 1.2rem;

        p {
          margin-bottom: 20px
        }
      }

      .mansory{
        background-color: #00adf4;
        margin-top:15px;
        padding:10px;
      }
    }
  }
  #link {
    display: none;
    margin: 50px;
    a {
      font-size: x-large;
      text-align: center;

      color: $color-2;

    }
  }

}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1001;
}


.float-label {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom:20px;

  input, textarea {
    width: 100%;
    height: 46px;
    padding: 14px 16px 0 5px;
    outline: 0;
    border-bottom: 1px solid $color-2;
    font-size: 18px;
    resize: none;
    font-family: font-1;
    background-color: transparent;
  }
  textarea {
    height: 350px!important;
  }

  label {
    font-size: 18px;
    padding: 0;
    color: #999;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 22px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;

    &:focus-within label {
      transform: translate(0, 1px) scale(0.75);
    }

    &.active {
      transform: translate(0, 1px) scale(0.75);
    }

    &.error {
      color:red;
    }
  }

  #not-mail, #not-mailCV {
    display: none;
    position: absolute;
    top: -25px;
    color: $red;
    right: 0;
    transform-origin: 50% 50%;
  }
  @keyframes dongle {
    0% {
      transform: translate(0px, 25px);
    }
    10% {
      transform: translate(-10px, 25px);
    }
    20% {
      transform: translate(10px, 25px);
    }
    30% {
      transform: translate(-10px, 25px);
    }
    40% {
      transform: translate(10px, 25px);
    }
    50% {
      transform: translate(-10px, 25px);
    }
    60% {
      transform: translate(10px, 25px);
    }
    70% {
      transform: translate(-10px, 25px);
    }
    80% {
      transform: translate(10px, 25px);
    }
    90% {
      transform: translate(-10px, 25px);
    }
    100% {
      transform: translate(0px, 25px);
    }
  }


}


